<template>
  <div id="ranking" class="wrapper">
    <div class="ranking">
      <nav class="btn-back" @click.stop="back()">
        <a href="#"><span class="sp-none">もどる</span></a>
      </nav>
      <h1>{{ data.facilityName }}ランキング</h1>
      <div class="ranking-wrapper">
        <ul class="entries">
          <li class="update-time">
            <p>取得日時: {{ lastDate }} 現在</p>
            <p>更新予定: {{ nextDate }} 頃</p>
          </li>

          <li v-if="rank" class="rank">
            <p>
              あなたのランク: <span class="myrank">{{ rank }}</span> 位 /
              {{ data.max }} 人中
            </p>
            <p>
              あなたのスコア: <span class="myrank">{{ score }}</span> ポイント
            </p>
            <p>
              合計タイム:
              <span v-if="time >= 3600">
                <span class="myrank">{{ Math.floor(time / 3600) }}</span>
                時間 </span
              ><span v-if="time >= 60"
                ><span class="myrank">{{ Math.floor(time / 60) }}</span>
                分 </span
              ><span
                ><span class="myrank">{{ Math.floor(time % 60) }}</span>
                秒</span
              >
            </p>
          </li>
          <li v-else>
            <p>データがありません</p>
          </li>

          <li
            v-for="(entry, index) in data.ranking"
            :key="'rank' + index"
            :class="['entry', 'rank' + entry.rank]"
          >
            <div class="entry-wrapper">
              <div class="score" v-html="entry.score"></div>
              <div class="name" v-html="entry.name"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import back from '@/mixins/back';
export default {
  name: 'Ranking',
  components: {},
  mixins: [back],
  props: {
    id: String
  },
  data() {
    return {
      data: {},
      last: 0,
      next: 0,
      rank: 0,
      score: 0,
      time: 0,
      timer: null
    };
  },
  computed: {
    lastDate() {
      return this.getDate(this.last).toLocaleString();
    },
    nextDate() {
      return this.getDate(this.next).toLocaleString();
    }
  },
  async mounted() {
    if (this.id === undefined) {
      this.$router.push({ name: 'AbilityMenu' }).catch(() => {});
      return;
    }
    await this.update();
  },

  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    getDate(utime) {
      return new Date(utime);
    },
    async update() {
      this.$tryLoadingAsync(async () => {
        const data = await this.$store.dispatch('api/getAbilityRanking');
        this.data = data[this.id];
        this.last = data.last;
        this.next = data.next;

        let user = data.user[this.id];
        if (user) {
          this.rank = user.rank;
          this.score = user.score;
          this.time = user.time;
        }

        let delay = this.next - new Date().getTime();
        console.log(delay);
        if (delay < 1) delay = 5 * 60 * 1000;
        this.timer = setTimeout(() => {
          this.update();
        }, delay);
      });
    },
    getTimeString(sec) {
      let str;
      str = `${sec % 60} 秒`;
      if (sec >= 60) str = `${Math.floor(sec / 60) % 60} 分 ` + str;
      if (sec >= 3600) str = `${Math.floor(sec / 3600)} 時間 ` + str;
      return str;
    }
  }
};
</script>

<style scoped>
.update-time {
  font-size: medium;
}
.update-time p {
  white-space: nowrap;
  line-height: 125%;
}
.rank {
  white-space: nowrap;
  font-size: large;
  line-height: 125%;
}
.myrank {
  font-weight: bold;
  /* background: linear-gradient(transparent 50%, #ff7f7f 65%); */
}
</style>
